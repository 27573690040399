import React, { useState  } from "react";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import AuthService from "../services/auth.service";


function Login(props) {

  const [ loading, setLoading ] = useState(false);
  const [ message, setMessage ] = useState("");

  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .required('Username is required'),
      //.email('Username is invalid'),
    password: Yup.string()
      .required('Password is required')
      .min(6, 'Password must be at least 6 characters')
      .max(40, 'Password must not exceed 40 characters'),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema)
  });

  const onSubmit = data => {
    setLoading(true)
    AuthService.login(data.username, data.password).then(
      () => {
        window.location.href= '/';
      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setLoading(false);
        setMessage(resMessage);
      }
    );
    reset()
  };

  return(
    <div class="row justify-content-center">
      <div class="col-xl-5 col-lg-7 col-md-8">
        <div class="card o-hidden border-0 shadow-lg my-5">
          <div class="card-body p-0">
            <div class="row">
              <div class="col-lg-12">
                <div class="p-5">
                  <div class="text-center">
                    <h1 class="h4 text-gray-900 mb-4">Welcome</h1>
                  </div>
                    <form class="user primary" onSubmit={handleSubmit(onSubmit)}>
                      <div class="form-group">
                        <label>Username</label>
                        <input
                          name="username"
                          type="text"
                          placeholder="Username"
                          {...register('username')}
                          className={`form-control form-control-user ${errors.username ? 'is-invalid' : ''}`}
                        />
                        <div className="invalid-feedback">{errors.username?.message}</div>
                      </div>
                      <div class="form-group">
                        <label>Password</label>
                        <input
                          name="password"
                          type="password"
                          placeholder="Password"
                          {...register('password')}
                          className={`form-control form-control-user ${errors.password ? 'is-invalid' : ''}`}
                        />
                        <div className="invalid-feedback">{errors.password?.message}</div>
                      </div>
                      <button 
                        type="submit" 
                        className="btn btn-primary btn-user btn-block"
                        disabled={loading}
                      >
                        {loading && (
                          <span className="spinner-border spinner-border-sm"></span>
                        )}
                        <span>Login</span>
                      </button>
                    </form>
                    {message && (
                      <div className="form-group">
                        <div className="alert alert-danger" role="alert">
                          {message}
                        </div>
                      </div>
                    )}
                  <hr/>
                  <div class="text-left">
                    <p class="small mb-0" >Copyright &copy; Ch-Hosting.de 2022</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login