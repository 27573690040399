import React, { Component } from "react";
import _map from 'lodash/map'
import Services from "../services/service.service";

export default class ApplicationViewComponent extends Component {
    constructor(props) {
      super(props);
      
      this.state = {
        services_content: [],
        reload: false
      };
    }
    
    componentDidMount() {
      Services.getAllServices().then(response => {
          this.setState({services_content: response})
      })
    }

    // componentDidUpdate() {
    //   console.log("Update was triggered")
    //   console.log(this.state.reload)
    //   Services.getAllServices().then(response => {
    //     this.setState({services_content: response})
    //   })
    //   this.state.reload = false
    // }

    render() {
        return(
            <>
            {_map(this.state.services_content, service => (
                <div class="col-xl-3 col-lg-4" name={service.id}>
                  <div class="card shadow mb-4">
                    <div
                        class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                        <h6 class="m-0 font-weight-bold text-primary"><a class="primary" href={service.url}>{service.title}</a></h6>
                        <div class="dropdown no-arrow">
                            <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="mdi mdi-dots-vertical mdi-24px text-gray-400"></i>
                            </a>
                            <div class="dropdown-menu dropdown-menu-right shadow animated--fade-in"
                                aria-labelledby="dropdownMenuLink">
                                <div class="dropdown-header">Actions:</div>
                                <a class="dropdown-item background-primary" data-toggle="modal" data-target="#editEntry" onClick={() => this.props.child_to_parent_set_edit_id(service.id)}>Edit</a>
                                <a class="dropdown-item background-primary" data-toggle="modal" data-target="#deleteEntry" onClick={ () => this.props.child_to_parent_set_delete_id(service.id)}>Delete</a>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row no-gutters align-items-center">
                            <div class="col mr-2">
                                <div class="h6 mb-0 font-weight-bold text-gray-800">{service.description}</div>
                            </div>
                            <div class="col-auto">
                              <i class={"mdi " + service.mdi_class + " mdi-48px text-gray-400"}></i>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
              ))}
            </>
        )
    }
}