import React, { useState  } from "react";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import Services from "../services/service.service";


function ApplicationAddComponent(props) {

  const [ loading, setLoading ] = useState(false);
  const [ message, setMessage ] = useState("");

  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .required('Title is required'),
    url: Yup.string()
      .required('Url is required')
      .url('Must be a valid url'),
    description: Yup.string()
    	.required('Description is required'),
    mdi_class: Yup.string()
    	.required('Mdi class is required'),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema)
  });

  const onSubmit = data => {
    setLoading(true)
    Services.postService(data).then(
        () => {
          console.log(data)
          props.child_to_parent_reload()
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
  
          setLoading(false);
          setMessage(resMessage);
        }
      );
		setLoading(false)
    reset()
  };

  return(
    <div class="col-xl-3 col-lg-4">
        <div class="card shadow mb-4">
            <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                <h6 class="m-0 font-weight-bold text-primary"><a class="primary">Add new application</a></h6>
            </div>
            <div class="card-body">
                <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                    <form class="user primary" onSubmit={handleSubmit(onSubmit)}>
                      <div class="form-group">
                        <input
                          name="title"
                          type="text"
                          placeholder="Title"
                          {...register('title')}
                          className={`form-control form-control-user ${errors.title ? 'is-invalid' : ''}`}
                        />
                        <div className="invalid-feedback">{errors.title?.message}</div>
                      </div>
                      <div class="form-group">
                        <input
                          name="url"
                          type="text"
                          placeholder="Url"
                          {...register('url')}
                          className={`form-control form-control-user ${errors.url ? 'is-invalid' : ''}`}
                        />
                        <div className="invalid-feedback">{errors.url?.message}</div>
                      </div>
                      <div class="form-group">
                        <textarea
                          name="description"
                          type="text"
                          placeholder="Description"
                          {...register('description')}
                          className={`form-control form-control-user ${errors.description ? 'is-invalid' : ''}`}
                          rows="3"
                        />
                        <div className="invalid-feedback">{errors.description?.message}</div>
                      </div>
                      <div class="form-group">
                        <input
                          name="mdi_class"
                          type="text"
                          placeholder="Symbol - mdi-class"
                          {...register('mdi_class')}
                          className={`form-control form-control-user ${errors.mdi_class ? 'is-invalid' : ''}`}
                        />
                        <div className="invalid-feedback">{errors.mdi_class?.message}</div>
                      </div>
                      <button 
                        type="submit" 
                        className="btn btn-primary btn-user btn-block"
                        disabled={loading}
                      >
                        {loading && (
                          <span className="spinner-border spinner-border-sm"></span>
                        )}
                        <span>Add</span>
                      </button>
                    </form>
                    {message && (
                      <div className="form-group">
                        <div className="alert alert-danger" role="alert">
                          {message}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ApplicationAddComponent