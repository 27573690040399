import axios from 'axios';
import authHeader from './auth-header';

const API_URL = window._env_.REACT_APP_BACKEND_URL + 'api/service';

 class Services {  
    getAllServices = async () => {
      const response = await axios.get(API_URL, { headers: authHeader() })
      return response.data
    }

    postService = async (service) => {
      const response = await axios.post(API_URL, service, { headers: authHeader() });
      return response.data
    }

    getServiceById = async (service_id) => {
      const response = await axios.get(API_URL + '/' + service_id, { headers: authHeader() });
      return response.data
    }

    updateServiceById = async (service_id, service) => {
      const response = await axios.put(API_URL + '/' + service_id, service, { headers: authHeader() });
      return response.data
    }

    deleteServiceById = async (service_id) => {
        const response = await axios.delete(API_URL + '/' + service_id, { headers: authHeader() });
        return response.data
    }

  }
  
  export default new Services();
  