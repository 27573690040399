import React, { Component } from "react";
import AuthService from "../services/auth.service";
export default class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = AuthService.getCurrentUser()
  }

  render() {
    return (
      <div class="container-fluid">				
        <div class="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 class="h3 mb-0 text-gray-800">Profile information: {this.state.full_name}</h1>
        </div>
        <div class="row">
        
          <div class="col-lg-6">
            <div class="card shadow mb-4">
              <a href="#collapseCardUserInfos" class="d-block card-header py-3" data-toggle="collapse" role="button" aria-expanded="true" aria-controls="collapseCardUserInfos">
                <h6 class="m-0 font-weight-bold text-primary">Profile details</h6>
              </a>
              <div class="collapse show" id="collapseCardUserInfos">
                <div class="card-body">
                  <ul class="list-group">
                    <li class="list-group-item"><i class="mdi mdi-circle mr-5"></i>User ID: {this.state.id}</li>
                    <li class="list-group-item"><i class="mdi mdi-circle mr-5"></i>Full name: {this.state.full_name}</li>
                    <li class="list-group-item"><i class="mdi mdi-circle mr-5"></i>Mail: {this.state.mail}</li>
                    <li class="list-group-item"><i class="mdi mdi-circle mr-5"></i>Token: {this.state.accessToken.substring(0, 20)} ...{" "}{this.state.accessToken.substr(this.state.accessToken.length - 20)}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          
          <div class="col-lg-6">
            <div class="card shadow mb-4">
              <a href="#collapseCardGroups" class="d-block card-header py-3" data-toggle="collapse" role="button" aria-expanded="true" aria-controls="collapseCardGroups">
                <h6 class="m-0 font-weight-bold text-primary">Group memberships</h6>
              </a>
              <div class="collapse show" id="collapseCardGroups" >
                <div class="card-body">
                  <ul class="list-group">
                    {this.state.groups && this.state.groups.map((groups, index) => <li class="list-group-item" key={index}><i class="mdi mdi-circle mr-5"></i>{groups}</li>)}
                  </ul>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    );
  }
}
