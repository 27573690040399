import React, { Component } from "react";
import { withRouter } from 'react-router'
import {BrowserRouter} from 'react-router-dom';
import _map from 'lodash/map'
import AuthService from "../services/auth.service";
import Services from "../services/service.service";

export default withRouter(class Navbar extends Component {
    constructor(props) {
      super(props);
      
			this.state = {
				user: AuthService.getCurrentUser(),
				content: []
			};
    }

		componentDidMount() {
			Services.getAllServices().then(response => {
				this.setState({content: response})
			})
		}

    logOut =(e) => {
        AuthService.logout();
        this.props.history.push('/login')
        window.location.reload();
    }

    render() {
        return(
            <BrowserRouter>
                <div id="wrapper">

                    <ul class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">


                        <a class="sidebar-brand d-flex align-items-center justify-content-center" href="/">
                            <div class="sidebar-brand-icon">
                                <img class="img-profile rounded-circle bg-light" src="img/favicon/android-icon-36x36.png" />
                            </div>
                            <div class="sidebar-brand-text mx-3">CH-HOSTING</div>
                        </a>


                        <hr class="sidebar-divider my-0"/>


                        <li class="nav-item active">
                            <a class="nav-link" href="/">
                                <i class="mdi mdi-speedometer mdi-18px"></i>
                                <span>Dashboard</span></a>
                        </li>


                        <hr class="sidebar-divider"/>


                        <div class="sidebar-heading">
                            Applications
                        </div>

												{_map(this.state.content, d => (<li class="nav-item" name={d.id}>
																													<a class="nav-link" href={d.url}>
																														<i class={"mdi " + d.mdi_class + " mdi-18px"}></i>
																														<span>{d.title}</span>
																													</a>
																												</li>))}

                        <hr class="sidebar-divider d-none d-md-block"/>

                        <div class="text-center d-none d-md-inline">
                            <button class="rounded-circle border-0" id="sidebarToggle"></button>
                        </div>

                    </ul>
                    <div id="content-wrapper" class="d-flex flex-column">

                        <div id="content">

                            <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">


                                <button id="sidebarToggleTop" class="btn btn-link d-md-none rounded-circle mr-3 text-primary">
                                    <i class="mdi mdi-menu mdi-24px"></i>
                                </button>


                                <ul class="navbar-nav ml-auto">


                                    <li class="nav-item dropdown no-arrow">
                                        <a class="nav-link dropdown-toggle" href="#" id="userDropdown" role="button"
                                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <span class="mr-2 d-none d-lg-inline text-gray-600 small">Hello {this.state.user.full_name}</span>
                                            <img class="img-profile rounded-circle"
                                                src="img/undraw_profile.svg"/>
                                        </a>

                                        <div class="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                                            aria-labelledby="userDropdown">
                                            <a class="dropdown-item background-primary" href="/profile">
                                                <i class="mdi mdi-account mdi-18px mr-2 text-gray-400"></i>
                                                Account
                                            </a>
                                            <div class="dropdown-divider"></div>
                                            <a class="dropdown-item background-primary" onClick={e=>this.logOut(e)}>
                                                <i class="mdi mdi-logout mdi-18px mr-2 text-gray-400"></i>
                                                Logout
                                            </a>
                                        </div>
                                    </li>

                                </ul>

                            </nav>

                        {this.props.component}

                        </div>
                        <footer class="sticky-footer bg-white">
                            <div class="container my-auto">
                                <div class="copyright text-center my-auto">
                                    <span>Copyright &copy; Ch-Hosting.de 2022</span>
                                </div>
                            </div>
                        </footer>
                    </div>
                </div>
            </BrowserRouter>
        )
    }
})