import React from "react";
import { Switch, Router , Route, Link, Redirect } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

import AuthService from "./services/auth.service";

import Login from "./components/login.component";
import Home from "./components/home.component";
import Profile from "./components/profile.component";
import Navbar from "./components/navbar.component";
import Navbar_Without from "./components/navbar_without.component"


// export default App;

function App () {
  const currentUser_login = AuthService.getCurrentUser();
  var isLogedIn = false;
  if (currentUser_login != undefined)
  {
    isLogedIn = true
  }
  var isLogedIn = false;
  if (currentUser_login != undefined)
  {
    isLogedIn = true
  }

	return (
    <div>
      <Switch >
        <Route exact path="/"> { isLogedIn ? <Navbar component={<Home />} /> : <Redirect to="/login" />} </Route>
        <Route exact path="/profile"> { isLogedIn ?  <Navbar component={<Profile />} /> : <Redirect to="/login" />} </Route>
        <Route exact path="/login"> { isLogedIn ? <Navbar component={<Home />} /> : <Navbar_Without component={<Login />} /> } </Route>
      </Switch >
    </div>
	)
}

export default App
