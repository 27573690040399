import React, { Component } from "react";
import _map from 'lodash/map'

import ApplicationDeleteComponent from "./application_delete.component";
import ApplicationEditComponent from "./application_edit.component";
import ApplicationAddComponent from "./application_add.component";
import ApplicationViewComponent from "./application_view.component";


export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      delete_id: '',
      edit_id:'',
      reload:false
    };
  }

  child_to_parent_set_delete_id = (service_id) => {
    this.setState({ delete_id: service_id })
  }

  child_to_parent_set_edit_id = (service_id) => {
    this.setState({ edit_id: service_id })
  }

  child_to_parent_reload = () => {
    console.log("Trigger reload")
    this.state.reload = true
  }

  render() {
    return (
      <div class="container-fluid">
        <div class="d-sm-flex align-items-center justify-content-between mb-4">
            <h1 class="h3 mb-0 text-gray-800">Dashboard</h1>
        </div>

          <div class="row">

          <ApplicationViewComponent reload = {this.state.reload} child_to_parent_set_edit_id = {this.child_to_parent_set_edit_id} child_to_parent_set_delete_id  = {this.child_to_parent_set_delete_id}/>

          <ApplicationAddComponent child_to_parent_reload = {this.child_to_parent_reload}/>

          </div>

        <a class="scroll-to-top rounded" href="#page-top">
          <i class="mdi mdi-chevron-up mdi-24px"></i>
        </a>

        <ApplicationEditComponent id={this.state.edit_id} />
        
        <ApplicationDeleteComponent id={this.state.delete_id} />

      </div>
    );
  }
}
