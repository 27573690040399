import React, { useState, useEffect } from "react";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Services from "../services/service.service";


function ApplicationEditComponent(props) {

  // Set default states
  const [ loading, setLoading ] = useState(false);
  const [ message, setMessage ] = useState("");
  const [ service_edit, setServiceEdit ] = useState("");
  const [ showModalEdit, setShowEdit ] = useState(false);
	const [ service_content, setServiceContent ] = useState([]);

  // Handler to open and close modal - delete entry
  const handleCloseEdit = () => 
  {
    setShowEdit(false)
    setMessage("")
    setServiceEdit("")
		setServiceContent([])
  } 
  const handleShowEdit = () => setShowEdit(true);

  // Change value after already updated
  useEffect(
    () => {
      if(props.id != "")
      {
        setServiceEdit(props.id)
				Services.getServiceById(props.id).then(response => {
					setServiceContent(response)
        	handleShowEdit()
				})
      }
    },
    [ props.id ]
  )
  
	const validationSchema = Yup.object().shape({
    title: Yup.string(),
    url: Yup.string()
    .url('Must be a valid url'),
		description: Yup.string(),
		mdi_class: Yup.string()
  });

	const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema)
  });

  const onSubmit = data => {
    // Remove empty keys
    for(let k in data) data[k] == '' && delete data[k]
    if (data != "")
    {
      setLoading(true)
      Services.updateServiceById(service_edit, data).then(
        () => {
          handleCloseEdit();
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setLoading(false);
          setMessage(resMessage);
        }
      );
      setLoading(false);
      reset()
    } else {
      handleCloseEdit();
    }
  };

  return(
    <form class="user primary" onSubmit={handleSubmit(onSubmit)}>
    <div class="modal fade" id="editEntry" tabindex="-1" show={showModalEdit} onHide={handleCloseEdit} role="dialog" aria-labelledby="editModal" aria-hidden="true">
      <div class="modal-dialog" role="document">
          <div class="modal-content">
              <div class="modal-header">
                  <h5 class="modal-title" id="editModal">Modify application </h5>
                  <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">×</span>
                  </button>
              </div>
              <div class="modal-body">
                  <div class="form-group">
                  <input
                    name="title"
                    type="text"
                    placeholder={service_content.title ? service_content.title : "Title"}
                    {...register('title')}
                    className={`form-control form-control-user ${errors.title ? 'is-invalid' : ''}`}
                  />
                  <div className="invalid-feedback">{errors.title?.message}</div>
                  </div>
                  <div class="form-group">
                  <input
                    name="url"
                    type="text"
                    placeholder={service_content.url ? service_content.url : "Url"}
                    {...register('url')}
                    className={`form-control form-control-user ${errors.url ? 'is-invalid' : ''}`}
                  />
                  <div className="invalid-feedback">{errors.url?.message}</div>
                  </div>
                  <div class="form-group">
                  <textarea
                    name="description"
                    type="text"
                    placeholder={service_content.description ? service_content.description : "Description"}
                    {...register('description')}
                    className={`form-control form-control-user ${errors.description ? 'is-invalid' : ''}`}
                    rows="3"
                  />
                  <div className="invalid-feedback">{errors.description?.message}</div>
                  </div>
                  <div class="form-group">
                  <input
                      name="mdi_class"
                      type="text"
                      placeholder={service_content.mdi_class ? service_content.mdi_class : "Symbol - mdi-class"}
                      {...register('mdi_class')}
                      className={`form-control form-control-user ${errors.mdi_class ? 'is-invalid' : ''}`}
                    />
                  </div>
                  <div className="invalid-feedback">{errors.mdi_class?.message}</div>
              </div>
              <div class="modal-footer">
                  <button class="btn btn-dark" type="button" data-dismiss="modal" onClick={handleCloseEdit} >Cancel</button>
                  <button 
                    type="submit"
                    className="btn btn-primary btn-user"
                    disabled={loading}
                  >
                  {loading && (
                    <span className="spinner-border spinner-border-sm"></span>
                  )}
                  <span>Save</span>
                  </button>
              </div>
              {message && (
                <div className="form-group">
                  <div className="alert alert-danger" role="alert">
                    {message}
                  </div>
                </div>
              )}
          </div>
      </div>
    </div>
    </form>
  )
}

export default ApplicationEditComponent