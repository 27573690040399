import React, { Component } from "react";

export default class Navbar extends Component {
    constructor(props) {
      super(props);
    }

    render() {
        return(
            <div class="bg-gradient-primary full_screen">
                <div class="container">
                    {this.props.component}
                </div>
            </div>
        )
    }
}