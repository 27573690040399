import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import Services from "../services/service.service";

function ApplicationDeleteComponent(props) {

  // Set default states
  const [ loading, setLoading ] = useState(false);
  const [ message, setMessage ] = useState("");
  const [ service_delete, setServiceDelete ] = useState("");
  const [ showModalDelete, setShowDelete ] = useState(false);

  // Handler to open and close modal - delete entry
  const handleCloseDelete = () => 
  {
    setShowDelete(false)
    setMessage("")
    setServiceDelete("")
  } 
  const handleShowDelete = () => setShowDelete(true);

  // Change value after already updated
  useEffect(
    () => {
      setServiceDelete(props.id)
      if(props.id != "")
      {
        handleShowDelete()
      }
    },
    [ props.id ]
  )
  
  // Method to submit delete request
  function onSubmit(service_id) {
    setLoading(true);
      Services.deleteServiceById(service_id).then(res =>{
        var element = document.getElementsByName(service_id), index;
        for (index = element.length - 1; index >= 0; index--) {
            element[index].parentNode.removeChild(element[index]);
        }
        setLoading(false);
        handleCloseDelete()
      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setLoading(false);
        setMessage(resMessage);
      }
    );
  };

  return(
    <form>
      <Modal id="deleteEntry" show={showModalDelete} onHide={handleCloseDelete}>
        <Modal.Header closeButton>
          <Modal.Title>Do you really want to delete the entry?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Click "Delete" if you are sure you want to remove the entry permanently.</Modal.Body>
        <Modal.Footer>
          <Button variant="dark" onClick={handleCloseDelete}>
            Cancel
          </Button>
          <Button
              data-dismiss="modal"
              className="btn btn-primary btn-user"
              disabled={loading}
              onClick={() => onSubmit(service_delete)}
          >
            {loading && (
              <span className="spinner-border spinner-border-sm"></span>
            )}
            <span>Delete</span>
          </Button>
        </Modal.Footer>
        {message && (
          <div className="form-group">
            <div className="alert alert-danger" role="alert">
              {message}
            </div>
          </div>
        )}
      </Modal>
    </form>
  )
}

export default ApplicationDeleteComponent